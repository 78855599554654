/*
Styling is done component-wise. Each component gets their own set of styles.
The styling for the responsive design appears at the end.
*/

* {
  box-sizing: border-box;
}


.hover-orange:hover {
  box-shadow: 0 0 10px orange;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.website-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.page-container {
  margin-left: 20%;
  width: 100%;
  transition: margin-left 0.5s ;
}




/*%%%%%%%%%%%%%%%%%% NAVIGATION BAR %%%%%%%%%%%%%%%%%*/

.nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #3b3838;
  width: 20%;
  transition: width 0.5s;
  z-index: 1;
  overflow: hidden;
}

.navbtn {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: orange;
  transition: 0.5s;
  font-size: 25px;
}

.navbtn-clicked {
  color: black;
  background-color: orange;
}

.navbtn:hover {
  cursor: pointer;
  box-shadow: 0 0 10px orange;
}

.logo-container {
  margin: 20px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.2s;
}

.logo-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.logo-container:hover {
  box-shadow: 0 0 10px orange;
/*  box-shadow: 0 0 10px orange;*/
}

.logo {
  position: absolute;
  width: 100%;
  height: 100%;
/*  transform: scale(1);*/
/*  top: 10%;*/
  object-fit: cover;
  cursor: pointer;
}


.navbar-burger {
  position: absolute;
  top: 10px;
  width: 10%;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {opacity: 0;
      translate: -10% }
  100% {opacity: 1;
        translate: 0%}
}


/*%%%%%%%%%%%%%%%%%% HOME %%%%%%%%%%%%%%%%%*/

.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 130px;
  padding: 20px;
  animation: fadeIn 0.2s;
}

.home-title-1 {
  display: flex;
  color: orange;
  font-size: 100px;
}

.home-title-2 {
  display: flex;
  color: orange;
  font-size: 30px;
}

.home-title-3 {
  display: flex;
  max-width: 768px;
  overflow: auto;
  margin-top: 40px;
  color: black;
  font-size: 20px;
  text-align: center;
}

.home-title-4 {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
}

.home-title-5 {
  cursor: pointer;
  color: orange;
}

.home-title-6 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.home-logo {
  max-width: 30px;
  margin-right: 20px;
}

.home-link {
  color: orange;
  text-decoration: none;
  cursor: pointer;
}

.fade-out {
  animation: fadeOut ease 4s !important;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }

  50% {
    opacity:1;
  }

  100% {
    opacity:0;
  }
}

.home-copy {
  color: green;
  user-select: none;
  opacity: 0;
}

/*%%%%%%%%%%%%%%%%%% WORK %%%%%%%%%%%%%%%%%*/

.center-wrapper {
  display: flex;
  justify-content: center;
}

.work-container {
  display: grid;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: 30% 50%;
  grid-template-areas: 
    " w-header w-header "
    " w-itemE1 w-itemE2 "
    " w-itemA1 w-itemA2 "
    " w-itemB1 w-itemB2 "
    " w-itemC1 w-itemC2 "
    " w-itemD1 w-itemD2 ";
  margin-top: 40px;
  margin-bottom: 40px;
  row-gap: 40px;
  animation: fadeIn 0.2s;
}

.work-title {
  position: relative;
  color: orange;
  font-size: 50px;
  grid-area: w-header;
  width: 100%;  
}

.work-title:after {
  content: "";
  background: orange;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
}


.work-logo-box {
  display: flex;
  align-items: center;
  height: 150px;
  width: 150px;
}

.work-logo {
  max-height: 150px;
  max-width: 150px;
  transition: box-shadow 0.2s;
}

.work-logo:hover {
  box-shadow: 0 0 10px orange;
/*  box-shadow: 0 0 10px orange;*/
}

.work-text {
  margin: auto;
  text-align: center;
}

.work-text-title {
  display: flex;
  flex-direction: row;
}

.work-text-title-first {
  font-weight: bold;
  font-size: 25px;
}

.work-text-title-last {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 20px;
}

.work-itemA1 {grid-area: w-itemA1;}
.work-itemB1 {grid-area: w-itemB1;}
.work-itemC1 {grid-area: w-itemC1;}
.work-itemD1 {grid-area: w-itemD1;}
.work-itemE1 {grid-area: w-itemE1;}
.work-itemA2 {grid-area: w-itemA2;}
.work-itemB2 {grid-area: w-itemB2;}
.work-itemC2 {grid-area: w-itemC2;}
.work-itemD2 {grid-area: w-itemD2;}
.work-itemE2 {grid-area: w-itemE2;}



/*%%%%%%%%%%%%%% EDUCATION %%%%%%%%%%%%%*/

.edu-container {
  display: grid;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: 26% 26% 26%;
  grid-template-areas: 
    "e-header e-header e-header"
    "e-itemA1 e-itemB1 e-itemC1"
    "e-itemA2 e-itemB2 e-itemC2";

  margin-top: 40px;
  row-gap: 40px;
  animation: fadeIn 0.2s;
}

.edu-title {
  position: relative;
  color: orange;
  font-size: 50px;
  grid-area: e-header;
  width: 100%;
}

.edu-title:after {
  content: "";
  background: orange;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
}



.edu-itemA1 {grid-area: e-itemA1;}
.edu-itemB1 {grid-area: e-itemB1;}
.edu-itemC1 {grid-area: e-itemC1;}
.edu-itemA2 {grid-area: e-itemA2;}
.edu-itemB2 {grid-area: e-itemB2;}
.edu-itemC2 {grid-area: e-itemC2;}

.edu-logo-box {
  display: flex;
  align-items: center;
  height: 150px;
  width: 150px;
  margin: auto;
}

.edu-logo {
  max-height: 150px;
  max-width: 150px;
  transition: box-shadow 0.2s;
}

.edu-logo:hover {
  box-shadow: 0 0 10px orange;
/*  box-shadow: 0 0 10px orange;*/
}

.edu-text {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.edu-text-title-first {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px;
}

.edu-text-title-second {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.edu-text-title-third {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 10px;
}





/*%%%%%%%%%%%%%%%%%%% THESIS %%%%%%%%%%%%%%%%%%%*/

.thesis-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s;
}

.thesis-title {
  position: relative;
  color: orange;
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.thesis-title:after {
  content: "";
  background: orange;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
}

.thesis-text-1 {
  max-width: 80%;
  font-size: 20px;
  text-align: center;
}

.thesis-box-container {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-top: 40px;
  justify-content: space-between;
}

.thesis-box {
  margin-top: 40px;
  max-width: 320px;
  text-align: center;
  position: relative;
}

.thesis-box-title {
  font-weight: bold;
  font-size: 25px;
}

.thesis-arrow {
  position: absolute;
  top: -20%;
  height: 60%;
}

.thesis-arrow-1 {
  left: -50%;

}

.thesis-arrow-2 {
  left: 116%;
  transform: scaleX(-1);
}



/*%%%%%%%%%%%%%%% COUNCIL %%%%%%%%%%%%%%%%%*/

.council-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s;
}

.council-title {
  display: flex;
  justify-content: center;
  position: relative;
  color: orange;
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.council-title:after {
  content: "";
  background: orange;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
}

.council-text-1 {
  max-width: 80%;
  font-size: 20px;
  text-align: center; 
}

.council-text-2 {
  margin-top: 40px;
  list-style-type:disc;
  font-size: 20px;
}

.council-text-2 li {
  margin-top: 25px;
}

.council-text-2-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}

/*%%%%%%%%%%%%%%%%% IAZZAI %%%%%%%%%%%%%%%%%%%%%*/
.ia-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s;
  padding-left: 20px;
  padding-right: 20px;
}

.ia-title {
  position: relative;
  color: orange;
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}

.ia-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 20px;
  max-width: 1024px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.ia-text-title {

  text-align: left;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ia-list-wrapper {
  text-align: left;
  margin-top: 10px;
  list-style-type:disc;
  font-size: 20px;
  padding-left: 20px;
}

.ia-logo {
  transition: box-shadow 0.2s;
}

.ia-logo:hover {
  box-shadow: 0 0 10px orange;
}


/*%%%%%%%%%%%%%%%%% LANG APP %%%%%%%%%%%%%%%%%%%%%*/
.lang-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s;
}

.lang-title {
  position: relative;
  color: orange;
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.lang-title:after {
  content: "";
  background: orange;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
}

.lang-box {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-bottom: 80px;
}

.lang-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  max-width: 730px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.lang-text-title {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.lang-text-2 {
  margin-left: auto;
}

.lang-pic-1 {
  margin-left: auto;
  max-height: 300px;
  max-width: 330px;
}

.lang-pic-2 {
  max-width: 430px;
  min-width: 380px;
}
.lang-pic-2-logo-row {
  display: flex;
  justify-content: space-evenly;
}

.lang-pic-2-logo {
  margin-right: 40px;
  max-height: 70px;
}


.center-wrapper-align {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}



/*%%%%%%%%%%%%%%%%% RESPONSIVE DESIGN %%%%%%%%%%%%%%%%%%%%%*/

@media only screen and (max-width: 1200px) {

  .nav {
    width: 20%;
  }

  .navbtn {
    font-size: 16px;
  }

  .home-title-1 {
    font-size: 70px;
  }

  .work-text-title {
    flex-direction: column;
  }

  .work-text-title-last {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .thesis-title {
    font-size: 40px;
  }

  .thesis-title:after {
    content: "";
    background: orange;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
  }

  .thesis-box-container {
    width: 100%;
    justify-content: space-between;
  }

  .thesis-box {
    margin-left: 5px;
  }

  .thesis-arrow {
    position: absolute;
    top: -20%;
    height: 60%;
  }

  .thesis-arrow-1 {
    left: -30%;

  }

  .thesis-arrow-2 {
    left: 100%;
    transform: scaleX(-1);
  }

  .lang-box {
    align-items: center;
    flex-direction: column;
  }

  .lang-box-2 {
    flex-direction: column-reverse;
  }

  .lang-pic-1 {
    margin-left: 0px;
  }

  .lang-text {
    margin-right: 0px;
  }

  .lang-pic-2 {
    min-width: 0%;
    width: 100%;
  }

  .lang-pic-2-logo {
    margin-right: 0px;
    max-height: 50px;
  }

} 


@media only screen and (max-width: 768px) {

  .page-container {
    margin-left: 0%;
  }

  .nav {
    width: 0%;
  }

  .home-title-1 {
    font-size: 50px;
  }

  .home-title-2 {
    font-size: 25px;
  }

  .work-container {
    justify-items: center;
    grid-template-columns: 80%;
    grid-template-areas: 
      " w-header"
      " w-itemE1 "
      " w-itemE2 "
      " w-itemA1 "
      " w-itemA2 "
      " w-itemB1 "
      " w-itemB2 " 
      " w-itemC1 "
      " w-itemC2 "
      " w-itemD1 " 
      " w-itemD2 ";
    margin-top: 40px;
    row-gap: 40px;
    animation: fadeIn 0.2s;
  }

  .work-text {
    margin-bottom: 40px;
  }

  .work-logo-box {
    margin-top: 0px;
  }

  .edu-container {
    justify-items: center;
    grid-template-columns: 80%;
    grid-template-areas: 
    "e-header"
    "e-itemA1" 
    "e-itemA2"
    "e-itemB1"
    "e-itemB2" 
    "e-itemC1" 
    "e-itemC2";
    margin-top: 40px;
    row-gap: 40px;
    animation: fadeIn 0.2s;
  }

  .council-title {
    font-size: 28px;
  }

  .work-title {
    font-size: 40px;
  }

  .thesis-title {
    font-size: 28px;
  }

  .thesis-box-container {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  .thesis-arrow {
    display: none;
  }

}

@media only screen and (max-width: 480px) {
  .home-title-1 {
    font-size: 30px;
  }

  .home-title-2 {
    font-size: 18px;
  }

  .work-title {
    font-size: 30px;
  }

  .council-title {
    font-size: 25px;
  }

  .lang-title {
    font-size: 30px;
  }
}

